import "./Boxes.css";
import {ReactNode} from "react";
import {RedLinkButton} from "../pages/HomePage";

type boxesProps = {
    title?: string;
    description?: string;
    children?: ReactNode;
    background?: string;
}

export function Boxes({title, description, children, background = "unset"}: boxesProps) {
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", background: background}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", paddingLeft: "20px", paddingRight: "20px"}}>
                <h2 style={{color: "var(--primary-text-color)"}}>{title}</h2>
                <label style={{color: "var(--primary-text-color)", fontSize: "22px"}}>{description}</label>
            </div>
            <div className={"boxes"} style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                {
                    children
                }
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"}/>
                <RedLinkButton name={"WERKWIJZE"} to={"/werkwijze"}/>
            </div>
        </div>
    )
}

type boxProps = {
    children?: ReactNode;
}

export function Box({children}: boxProps) {
    return (
        <div style={{
            background: "var(--main-background-color)",
            maxWidth: "220px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "20px",
            margin: "10px",
            fontSize: "18px",
            boxShadow: "#0000003b 0px 0px 7px 0px"
        }}>
            {
                children
            }
        </div>
    )
}

export function BoxWhite({children}: boxProps) {
    return (
        <div style={{
            background: "white",
            maxWidth: "220px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "20px",
            margin: "10px",
            fontSize: "18px",
            boxShadow: "#0000003b 0px 0px 7px 0px"
        }}>
            {
                children
            }
        </div>
    )
}