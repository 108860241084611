import {JSX} from "react";
import dummyImageSolar from '../assets/sun-blue-sky.png';
import {RedLinkButton} from "./HomePage";
import {Link} from "react-router-dom";

export default function ArticleSolarBoiler(): JSX.Element {
    return (
        <div className="main_container">
            <div style={{
                backgroundImage: `url(${dummyImageSolar})`, backgroundSize: "cover", height: "240px", display: "flex",
                flexDirection: "column", justifyContent: "flex-end"
            }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        maxWidth: "900px",
                        paddingLeft: "20px"
                    }}>
                        <h1 style={{fontSize: "45px", textShadow: "1px 1px 3px BLACK"}}>
                            Gebruikt opgewekte zonnestroom meer zelf
                        </h1>
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px"
            }}>
                <article style={{width: "100%", maxWidth: "900px"}}>
                    <h4 style={{color: "var(--primary-text-color)"}}>
                        Wanneer er veel zon is wordt er meer stroom opgewekt dan dat er verbruikt wordt.
                        Meer thuiswerken en de aanschaf van een boiler kan dit probleem verkleinen, zeggen
                        energiebedrijven.
                    </h4>
                    De meeste eigenaren van zonnepanelen gebruik maar <Link
                    to="https://www.milieucentraal.nl/energie-besparen/zonnepanelen/meer-zonnestroom-zelf-verbruiken/#:~:text=Van%20de%20zonnestroom%20die%20jouw,van%20je%20zonnestroom%20te%20verbruiken.">30%
                    procent</Link> van hun eigen opgewekte zonnestroom.
                    Het overige gedeelte van deze stroom wordt terug geleverd aan het energie-net. Door de
                    salderingsregeling kan de teruggeleverd stroom nog verrekent worden tegen de gebruikte stroom.
                    <br/>
                    <br/>
                    Echter wilt de regering deze regeling in <Link
                    to="https://www.nu.nl/klimaat/6313117/nieuwe-coalitie-wil-salderingsregeling-zonnepanelen-alsnog-schrappen-in-2027.html">2027
                    afschaffen</Link>, waardoor het interessanter gaat worden om
                    zoveel mogelijk van het opgewekte stroom zelf te gebruiken of op te slaan voor andere momenten.

                    <h4 style={{color: "var(--primary-text-color)"}}>
                        Water verwarmen met zonnestroom
                    </h4>
                    Door het gebruiken van een zonneboiler kan tot wel 80% van de zelf opgewekte stroom worden
                    gebruikt, <Link
                    to="https://group.vattenfall.com/nl/newsroom/persbericht/2024/gebruik-eigen-zonnestroom-voor-warme-douche#:~:text=Met%20de%20zonnestroomboiler%20kan%20tot,het%20afschaffen%20van%20de%20salderingsregeling.">beweert
                    Vattenfall</Link>.
                    Een boiler zou dus een goede voorbereiding zijn op het afschaffen van de salderingsregeling, aldus
                    het energiebedrijf.
                    <br/>
                    <br/>
                    Door het verwarmde water uit de boiler eerst aan te bieden aan de cv-ketel, kan de cv-ketel
                    eventueel na verwarmen tot 60 graden. Uiteindelijk gaat er 60 graden aan water naar de kraan, douche
                    en /of bad. Mocht er te weinig zonnestroom opgewekt worden, dan zal de cv-ketel het verschil bij
                    verwarmen.
                    <br/><br/>
                    Door gebruikt te maken van een <Link to="/werkwijze">RS-Solar Energy systeem</Link> kan
                    er optimaal gebruik
                    gemaakt worden van de overige zonnestroom. Dit komt doordat het systeem precies de juiste
                    hoeveelheid terug geleverde zonnestroom gebruikt voor het opladen van de boiler. Dit in combinatie
                    met
                    een dynamisch energie contract, kan het systeem zelfs de boilers verwarmen op momenten dat de stroom
                    goedkoop
                    geïmporteerd kan worden. Dit kan bijvoorbeeld zijn wanneer er veel zon en /of wind -energie wordt
                    opgewekt. Hierdoor kan het systeem ook erg aantrekkelijk zijn voor
                    huishouders <u>zonder</u> zonnepanelen.
                    <div
                        style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"}/>
                        <RedLinkButton name={"WERKWIJZE"} to={"/werkwijze"}/>
                    </div>
                    <h4 style={{color: "var(--primary-text-color)"}}>
                        Weinig vraag naar de thuisbatterij
                    </h4>
                    De overtollige zou natuurlijk ook opgeslagen kunnen worden in een thuisbatterij, zodat deze energie
                    op latere momenten van de dag kan gebruiken. Bij energiebedrijf Vattenfall is hier nog weinig vraag
                    naar.
                    Dit komt doordat de kosten van de thuisbatterijen erg hoog zijn en de tijd dat het terug verdient
                    is te lang duurt.
                    <h4 style={{color: "var(--primary-text-color)"}}>
                        De uitdaging
                    </h4>
                    Doordat het nu nog niet mogelijk is om stroom in grote hoeveelheid op te slaan, moet vraag en aanbod
                    altijd in balans zijn. De netbeheerder <Link
                    to="https://www.tennet.eu/nl/over-tennet">TenneT</Link> lost de stroom overschot aan energie in de
                    praktijk
                    op,
                    echter worden de gemaakte kosten aan de energiebedrijven doorgerekend. Steeds meer energiebedrijven
                    gaan deze kosten in rekening brengen bij hun klant die te veel stroom terug leveren aan het
                    energie-net.
                    <div
                        style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"}/>
                    </div>
                </article>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "10px",
                boxShadow: "inset 0px 0px 2px 0px black"
            }}/>
        </div>
    );
};