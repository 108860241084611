import React, {JSX, useEffect, useState} from 'react';
import {Link, Outlet, Params, useParams} from "react-router-dom";
import logo from '../../assets/logo_rs-solar.svg';
import burger from '../../assets/icon_burger.svg';
import xMark from '../../assets/icon_x-mark.svg';
import './Menu.css';
import {RedLinkButton} from "../../pages/HomePage";
import {Footer} from "../Footer";

type Page = {
    title: string;
    to: string;
    param: string;
    pages?: Page[];
};

export default function Menu(): JSX.Element {
    const [showMenu, setShowMenu] = useState<boolean>(false);

    return (
        <>
            <div>
                <div style={{width: "100%", display: "inline-flex", justifyContent: "center", boxShadow: "rgba(0, 0, 0, 0.23) 0px 0px 7px 0px"}}>
                    <div style={{width: "900px", display: "flex"}}>
                        <Link to={"/"}><img src={logo} style={{height: "90px"}} alt=""/></Link>
                        <div style={{
                            height: "90px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: "auto"
                        }}>
                            <Link className={"menu-howDoesItWork"} to={"/werkwijze"}>Hoe het werkt</Link>
                        </div>
                        <div style={{
                            height: "90px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>
                            <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"} fontSize={20}/>
                        </div>
                        <img className={"menu-button"} style={{height: "90px"}} src={showMenu ? xMark : burger} alt="" onClick={() => setShowMenu(!showMenu)}/>
                    </div>
                </div>
                {showMenu && <MenuItems/>}
                <Outlet/>
                <Footer/>
            </div>
        </>
    )
}

function MenuItems() {
    return (
        <div style={{width: "100%", display: "inline-flex", justifyContent: "center"}}>
            <div className={"menu-items"} style={{width: "900px", display: "flex"}}>
                <MenuItem to={"/"} name={"Home"}/>
                <MenuItem to={"/winkel"} name={"Webshop"}/>
                <MenuItem to={"/toebehoren"} name={"Toebehoren"}/>
                <MenuItem to={"/werkwijze"} name={"Werkwijze"}/>
                <MenuItem to={"/installatie"} name={"Installatie"}/>
                <MenuItem to={"artikels/zonnestroomboiler"} name={"Artikels"}/>
            </div>
        </div>
    )
}

type MenuItemProps = {
    to: string;
    name?: string;
}

function MenuItem({to, name}: MenuItemProps) {
    const [selected, setSelected] = useState<boolean>(false);
    const params: Readonly<Params> = useParams();

    useEffect(() => {
        let current = params["*"] ?? "";

        if (current != "" && to.includes(current) || current == "" && to === "/") {
            setSelected(true)
        }
        else setSelected(false);
    }, [params])

    return (
        <Link className={`menu-item ${selected ? "menu-item-selected" : ""}`} to={`${to}`}>{name}</Link>
    )
}