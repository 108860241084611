import {useState} from "react";

const api_url = process.env.REACT_APP_API_URL;

interface ApiResponse<T> {
    success?: boolean;
    data?: T;
    error?: string;
    message?: string;
}

type ApiErrorResponse = {
    data: {
        error: string
    }
}

export interface RequestOptions {
    method: string;
    headers?: { [key: string]: string };
    body?: string | FormData;
}

function useApi<T>(url: string) {
    const [data, setData] = useState<T | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ApiErrorResponse | null>(null);

    const makeRequest = async (requestData: RequestOptions, params?: string) => {
        setLoading(true);
        try {
            setError(null);
            const response = await fetch(`${url}${params ?? ""}`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.jsw_token}`,
                    'Content-type': 'application/json; charset=UTF-8' // TODO: When sending binary, this needs to be disabled.
                },
                ...requestData,
            });
            if (!response.ok) {
                setData(null);
                const responseBody: ApiErrorResponse = await response.json();
                const errorData = responseBody.data;
                return setError(responseBody);
            }

            const responseBody: ApiResponse<T> = await response.json();

            setData(responseBody.data || null);
            setError(null);
        } catch (error: unknown) {
            let message = 'Unknown Error';
            if (error instanceof Error) message = error.message;
            setError({data: {error: message}});
        } finally {
            setLoading(false);
        }
    }

    const makeRequest1 = async (requestData: RequestOptions, params?: string) => {
        setLoading(true);
        try {
            const response = await fetch(`${api_url}/${url}${params ?? ""}`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.jsw_token}` // TODO: When sending binary, this needs to be disabled.
                },
                ...requestData,
            });

            const responseBody: ApiResponse<T> = await response.json();

            setData(responseBody.data || null);
            setError(null);
        } catch (error: unknown) {
            console.log("ERROR!")
            let message = 'Unknown Error';
            //if (error instanceof Error) message = error.message;
            //setError(message);
        } finally {
            setLoading(false);
        }
    }

    return { data, loading, error, makeRequest, makeRequest1 };
}

export default useApi;