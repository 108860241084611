import './Buttons.css';
import React, {ButtonHTMLAttributes, JSX, ReactNode, useEffect, useRef, useState} from "react";
import useApi from "../../utils/hooks/useApi";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    name: string;
    children?: ReactNode;
    clickEvent?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    warning?: boolean;
    skin?: "normal" | "delete"
}

export default function Button({name, skin, children, ...restProps}: ButtonProps): JSX.Element {
    const [className, setClassName] = useState("form-button");

    useEffect(() => {
        switch (skin) {
            case "delete":
                setClassName("form-button form-button-delete")
                break
            default:
            case "normal":
                break;
        }
    }, [skin])


    return (
        <button className={className} {...restProps}>
            {name}
            {children}
        </button>
    )
}

interface ButtonUploadProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    url?: string;
    parentId?: string;
    callBack?: () => void;
}


export function ButtonUpload({children, url, parentId, callBack, ...resetProps}: ButtonUploadProps): JSX.Element {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {makeRequest1: uploadFile, data} = useApi(`${url}`);

    const handleClick = () => {
        // Trigger a click on the file input
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Handle file changes here
        if (e.target.files) {
            const file = e.target.files[0];

            // Process the selected files
            console.log(file);
            const formData = new FormData();
            formData.append("formFile1", file)

            if (url) {
                uploadFile({
                    method: "POST",
                    body: formData
                }, `/${parentId}/${file.name}/content`)
                    .catch(error => console.error(error));
            }
        }
    };

    useEffect(() => {
        callBack && callBack();
    }, [data])

    return (
        <div>
            <button style={{
                backgroundColor: "unset",
                border: "none",
                color: "unset",
                fontFamily: "unset",
                fontSize: "16px"
            }} {...resetProps} onClick={handleClick}>{children}</button>
            <input
                type="file"
                ref={fileInputRef}
                style={{display: 'none'}}
                onChange={handleFileChange}
            />
        </div>
    );
}