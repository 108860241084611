import {JSX, ReactNode} from "react";
import dummyImageSolar from '../assets/rs-solar-thumbnail.png';
import {CheckMarkBoxes} from "../components/CheckMarkBoxes";
import {ArticleContainer2} from "../components/ArticleComponent";
import dummyAanUit from "../assets/bang_control.svg";
import dummyGestuurd from "../assets/variable_control.svg";
import rsSolarEnergyController from "../assets/RS-Solar Energy 1-Fase_Camera_Camera RS-Solar Energy 2.png";
import multipleBoilers from "../assets/RS-Solar Energy 1-Fase 2 Boilers (Transparant).png";

export default function HowDoesItWorkPage(): JSX.Element {
    return (
        <div className="main_container">
            <div style={{
                backgroundImage: `url(${dummyImageSolar})`, backgroundSize: "cover", display: "flex",
                flexDirection: "column", justifyContent: "flex-end"
            }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        maxWidth: "900px",
                        paddingLeft: "20px"
                    }}>
                        <h1 style={{fontSize: "45px", textShadow: "1px 1px 3px BLACK"}}>Werkwijze</h1>
                        <label style={{fontSize: "20px", textShadow: "1px 1px 3px BLACK"}}>

                        </label>
                        <div style={{display: "flex", flexDirection: "row"}}>
                        </div>
                    </div>
                </div>
                <CheckMarkBoxes
                    titles={["Bespaar op gasverbruik", "Meer zelfvoorzienend", "Verdien aan zonne energie"]}/>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <div className="article-component"
                     style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px"}}>
                    <div>
                        <img style={{
                            borderRadius: "10px",
                            boxShadow: "rgb(0 0 0 / 23%) 0px 0px 7px 0px", width: "460px", maxWidth: "100%"
                        }} src={rsSolarEnergyController}
                             alt=""/>
                    </div>
                    <div style={{maxWidth: "480px", paddingLeft: "20px", paddingRight: "20px"}}>
                        <h2 style={{color: "var(--primary-text-color)"}}>
                            Slim energie opslaan in water met behulp van een P1 meter
                        </h2>
                        <article>
                            <b>RS-Solar Energy systeem</b> maakt gebruik van de de HomeWizard P1 meter. Met de
                            HomeWizard P1
                            meter kan het systeem meten hoeveel energie er over is in je huis. Met de energie die over
                            is, kan dan de elektrische boiler worden aangestuurd.
                            De HomeWizard P1 meter wordt door het systeem automatisch gevonden en uitgelezen. Met de
                            gegevens van de HomeWizard P1 meter wordt daarna het juiste vermogen richting de elektrische
                            boiler gestuurd.
                        </article>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            minHeight: "12px"
                        }}/>
                    </div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <div className="article-component"
                     style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px"}}>
                    <div style={{maxWidth: "480px", paddingLeft: "20px", paddingRight: "20px"}}>
                        <h2 style={{color: "var(--primary-text-color)"}}>Voorbeeld 1:</h2>
                        <article>
                            Je hebt een boiler van 2000Watt en u heeft maar 300Watt van uw zonnepanelen over.
                            De boiler wordt aangezet, dan wordt er 2000Watt gebruikt, waarbij er 300Watt uit het het
                            energie net wordt gehaald.
                            Dat is niet natuurlijk niet gewenst.<br/>Het mooiste zal natuurlijk zijn dat uw boiler
                            precies 300Watt
                            gebruikt, zonder dat er energie uit het energienet gehaald moet worden.<br/>
                            Het <b>RS-Solar Energy systeem</b> verwarmt uw boiler traploos, met de zonne energie van uw
                            dak.
                        </article>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            minHeight: "12px"
                        }}/>
                    </div>
                    <div>
                        <img style={{
                            borderRadius: "10px",
                            boxShadow: "rgb(0 0 0 / 23%) 0px 0px 7px 0px", width: "500px", maxWidth: "100%"
                        }} src={dummyAanUit}
                             alt=""/>
                    </div>
                </div>
            </div>


            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <div className="article-component"
                     style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px"}}>
                    <div style={{maxWidth: "480px", paddingLeft: "20px", paddingRight: "20px"}}>
                        <h2 style={{color: "var(--primary-text-color)"}}>Voorbeeld 2:</h2>
                        <article>
                            Je hebt een boiler van 2000Watt en u heeft maar 300Watt van uw zonnepanelen over.
                            Het <b>RS-Solar Energy systeem</b> zal 300Watt aan vermogen naar je boiler sturen. Doordat
                            het <b>RS-Solar Energy systeem</b> gebruik maakt van de HomeWizard P1 meter, kan het energie
                            verbuik
                            van je boiler contant worden aangepast. Mocht er een ander apparaat alle zonne energie
                            gebruiken dan zal het RS-Solar Energy geen energie meer uitsturen naar je boiler.
                            Mocht er weer zonne energie beschikbaar zijn, dan zal het RS-Solar Energy weer energie
                            uitsturen naar uw boiler.
                        </article>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            minHeight: "12px"
                        }}/>
                    </div>
                    <div>
                        <img style={{
                            borderRadius: "10px",
                            boxShadow: "rgb(0 0 0 / 23%) 0px 0px 7px 0px", width: "500px", maxWidth: "100%"
                        }} src={dummyGestuurd}
                             alt=""/>
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "10px",
                boxShadow: "inset 0px 0px 2px 0px black",
                marginTop: "10px"
            }}/>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <div className="article-component"
                     style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px"}}>
                    <div>
                        <img style={{
                            borderRadius: "10px",
                            boxShadow: "rgb(0 0 0 / 23%) 0px 0px 7px 0px", width: "500px", maxWidth: "100%"
                        }} src={multipleBoilers}
                             alt=""/>
                    </div>
                    <div style={{maxWidth: "480px", paddingLeft: "20px", paddingRight: "20px"}}>
                        <h2 style={{color: "var(--primary-text-color)"}}>Meerdere boilers aansluiten</h2>
                        <article>
                            Het is ook mogelijk om meerdere boiler op het <b>RS-Solar Energy systeem</b> aan te sluiten.
                            De standaard RS-Solar Energy besturing is standaard uitgerust met twee stopcontacten. Aan
                            beide stopcontacten kan een boiler van maximaal 3000Watt.<b/><b/> Het <b>RS-Solar Energy
                            systeem</b> begint iedere dag met het verwarmen van de 1e boiler. Als de 1e boiler op
                            temperatuur is, zal het <b>RS-Solar Energy systeem</b> overschakelen naar de 2e boiler.
                            Wanneer de 2e boiler is opgeladen, dan zal het systeem proberen om beide boilers op
                            temperatuur te houden.
                            <b/>
                            Het <b>RS-Solar Energy systeem</b> kunnen wij op aanvraag ook uitbereiden naar meer dan 2
                            boilers.
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};