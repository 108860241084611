import checkMark from "../assets/icon_checkmark_solid.svg";
import "./CheckMarkBoxes.css";

type checkMarkBoxesProps = {
    titles: string[];
}

/**
 * Creates checkbox containers with a title.
 * @param titles
 * @constructor
 */
export function CheckMarkBoxes({titles}: checkMarkBoxesProps) {
    return (
        <div className={"check-marks"} style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "auto",
            background: "rgba(52,169,75,0.8)"
        }}>
            {
                titles.map((value: string) => <CheckmarkBox key={value} title={value}/>)
            }
        </div>
    )
}

type checkMarkBoxProps = {
    title: string;
}

/**
 * Creates a container with a check icon and a title.
 * @param title
 * @constructor
 */
export function CheckmarkBox({title}: checkMarkBoxProps) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "10px",
            marginTop: "20px",
            marginBottom: "20px",
            justifyContent: "space-evenly",
            textShadow: "black 1px 1px 1px"
        }}>
            <img style={{height: "50px", margin: "5px"}} src={checkMark} alt=""/>
            <h3>{title}</h3>
        </div>
    );
}