import './ArticleComponent.css';
import {ReactNode} from "react";
import {RedLinkButton} from "../pages/HomePage";


type articleContainerProps = {
    title: string;
    children?: ReactNode;
    src?: string;
    disableShopButton?: boolean;
}

export function ArticleContainer({title, children, src, disableShopButton = false}: articleContainerProps) {
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div className={"article-component"} style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px"}}>
                <div>
                    <img style={{borderRadius: "10px",
                        boxShadow: "rgb(0 0 0 / 23%) 0px 0px 7px 0px", maxWidth: "100%"}} src={src} alt=""/>
                </div>
                <div style={{maxWidth: "500px", paddingLeft: "20px", paddingRight: "20px"}}>
                    <h2 style={{color: "var(--primary-text-color)"}}>{title}</h2>
                    <article>
                        {children}
                    </article>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", minHeight: "12px"}}>
                        {!disableShopButton && <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"}/>}
                        {!disableShopButton && <RedLinkButton name={"HOE HET WERKT"} to={"/werkwijze"}/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ArticleContainer2({title, children, src, disableShopButton = false}: articleContainerProps) {
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div className="article-component" style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px"}}>
                <div style={{maxWidth: "500px", paddingLeft: "20px", paddingRight: "20px"}}>
                    <h2 style={{color: "var(--primary-text-color)"}}>{title}</h2>
                    <article>
                        {children}
                    </article>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", minHeight: "12px"}}>
                        {!disableShopButton && <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"}/>}
                        {/*<RedLinkButton name={"OVER ONS"}/>*/}
                    </div>
                </div>
                <div>
                    <img style={{borderRadius: "10px",
                        boxShadow: "rgb(0 0 0 / 23%) 0px 0px 7px 0px", maxWidth: "100%"}} src={src}
                         alt=""/>
                </div>
            </div>
        </div>
    )
}