import './Footer.css';
import {Link} from "react-router-dom";
import iconInstagram from "../assets/icon_instagram.svg";
import iconYoutube from "../assets/icon_youtube.svg";
import iconFacebook from "../assets/icon_facebook.svg";
import iconLinkedin from "../assets/icon_linkedin.svg";
import logo from "../assets/logo_rs-solar.svg";
import React, {useEffect, useState} from "react";
// import CookieConsent, {Cookies} from "react-cookie-consent";
// import ReactGA4 from 'react-ga4';

export function Footer() {
    /*
    const [consented, setConsented] = useState(false);

    useEffect(() => consent(), [])

    const consent = () => {
        const cookieConsent = Cookies.get("myCookieConsent");
        if (!cookieConsent)
            return
        ReactGA4.initialize(`${process.env.REACT_GA_ID}`);
        ReactGA4.send({ hitType: "pageview", page: window.location.href, title: "Custom Title" });
    }

    const handleConsent = () => {
        setConsented(true);
        consent();
    };
    */

    return (
        <div>
            <div style={{
                backgroundColor: "var(--main-background-color)",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
            }}>
                <div className={"footer-component"}
                     style={{display: "flex", justifyContent: "center", alignItems: "flex-start"}}>
                    <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                        <h2>RS-Solar Energy</h2>
                        <label><b>Adres:</b><br/>Uitmaat 10b, 6987 ER Giesbeek</label><br/>
                        <label><b>Telefoon:</b><br/>+31 (0)85 06 08 250</label><br/><br/>
                        <Link to={"/"} style={{color: "white"}}>Home</Link><br/>
                        <Link to={"/winkel"} style={{color: "white"}}>Webshop</Link><br/>
                        <Link to={"/werkwijze"} style={{color: "white"}}>Werkwijze</Link><br/>
                        <Link to={"/installatie"} style={{color: "white"}}>Installatie</Link><br/>
                        {/*<Link to={""} style={{color: "white", display: "none"}}>Meestgestelde vragen</Link><br/>*/}
                        {/*<Link to={""} style={{color: "white", display: "none"}}>Reviews</Link><br/>*/}
                        {/*<Link to={""} style={{color: "white", display: "none"}}>Contact</Link><br/>*/}
                        <div>
                            <Link to={"https://www.instagram.com/rs__solarenergy?igsh=MTJ1MWd4NGx2YTRx&utm_source=qr"}><img src={iconInstagram} alt={""} style={{
                                padding: "5px",
                                height: "25px"
                            }}/></Link>
                            <Link to={"/"} style={{display: "none"}}><img src={iconYoutube} alt={""}
                                                                          style={{
                                                                              padding: "5px",
                                                                              height: "25px"
                                                                          }}/></Link>
                            <Link to={"/"} style={{display: "none"}}><img src={iconFacebook} alt={""}
                                                                          style={{
                                                                              padding: "5px",
                                                                              height: "25px"
                                                                          }}/></Link>
                            <Link
                                to={"https://www.linkedin.com/company/rs-solar-energy?trk=public_post_feed-actor-image"}><img
                                src={iconLinkedin} alt={""}
                                style={{padding: "5px", height: "25px"}}/></Link>
                        </div>
                        <label>NL856162255B01</label><br/>
                        <label>K.v.K. nr. 65560418</label><br/>
                    </div>
                    <div style={{padding: "10px"}}>
                        <img src={logo} alt={""} style={{maxWidth: "100%", width: "100%"}}/>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    background: "#68bd3b"
                }}>
                    <label>2024 © RS-Solar Energy is een onderdeel van <Link to={"https://rs-machinebouw.nl"}
                                                                                    style={{color: "white"}}>RS-Machinebouw
                        b.v.</Link> | Marketing: <Link to={"https://fiermarketing.nl/"}
                                                                  style={{color: "white"}}>FIER Marketing</Link>
                    </label><br/>
                </div>
            </div>
            {/*<CookieConsent enableDeclineButton*/}
            {/*    onAccept={handleConsent}*/}
            {/*    location="bottom"*/}
            {/*    buttonText="Accepteren"*/}
            {/*    cookieName="myCookieConsent"*/}
            {/*    declineButtonText="Afwijzen"*/}
            {/*    expires={7}>*/}
            {/*    Wij gebruiken cookies om onze website en onze service te optimaliseren: <a href="/privacy-policy" style={{color: "white"}}>Privacy Policy</a>.*/}
            {/*</CookieConsent>*/}
        </div>
    )
}