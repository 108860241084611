import {JSX, ReactNode} from "react";
import dummyImageSolar from '../assets/rs-solar-thumbnail.png';
import {CheckMarkBoxes} from "../components/CheckMarkBoxes";
import boilerInstallation from "../assets/intallation_boiler1_flow.jpg";
import boiler2Installation from "../assets/intallation_boiler2_flow.jpg";
import installation1 from "../assets/praktijk1.jpg";
import installation2 from "../assets/praktijk2.jpg";
import installation3 from "../assets/praktijk3.jpg";
import installation4 from "../assets/praktijk4.jpg";
import installation5 from "../assets/praktijk5.jpg";
import fileIcon from "../assets/icon_file.svg";
import {Product} from "./ShopPage";
import {Link} from "react-router-dom";
import useApi from "../utils/hooks/useApi";
import Button from "../components/common/Buttons";

export default function ControlPage(): JSX.Element {
    const {makeRequest, data} = useApi("/ping");

    return (
        <div className="main_container">
           <div>
               <h2 style={{color: "red"}}>RSS</h2>
               <Button name={"PING"} onClick={() => {
                   const defaultOptions = {
                       method: "GET"
                   };
                   makeRequest(defaultOptions, ``)
                       .catch((error) => alert(error));
               }}/>
           </div>
        </div>
    );
};