import {JSX, ReactNode} from "react";
import chevronLeft from '../assets/chevron_left.svg';
import chevronRight from '../assets/chevron_right.svg';
import storyBoardImage from '../assets/storyBoard.png';
import dummyImageSolar from '../assets/rs-solar-thumbnail.png';
import dummySolarHouse from '../assets/dumySolarPanels.jpeg';
import dummyRemcoImage from "../assets/dummyRemcoImage.jpg";
import dummyGeld from "../assets/dummyGeld.png";
import {Link} from "react-router-dom";
import {ArticleContainer, ArticleContainer2} from "../components/ArticleComponent";
import {CheckMarkBoxes} from "../components/CheckMarkBoxes";
import {Box, Boxes, BoxWhite} from "../components/Boxes";

export default function HomePage(): JSX.Element {
    return (
        <div className="main_container">
            <div style={{
                backgroundImage: `url(${dummyImageSolar})`, backgroundSize: "cover", display: "flex",
                flexDirection: "column", justifyContent: "flex-start"
            }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        maxWidth: "900px",
                        paddingLeft: "20px"
                    }}>
                        <h1 style={{fontSize: "45px", textShadow: "1px 1px 3px BLACK"}}>Maximaliseer het rendement van
                            jouw
                            zonne energie</h1>
                        <label style={{fontSize: "20px", textShadow: "1px 1px 3px BLACK"}}>
                            Met een <b>slimme wateraccu</b> die opgewekte zonne-energie gebruikt voor het verwarmen van
                            kraanwater
                        </label>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"}/>
                        </div>
                    </div>
                </div>
                <CheckMarkBoxes titles={["Bespaar op gasverbruik", "Meer zelfvoorzienend", "Verdien aan zonne energie"]}/>
            </div>
            <ArticleContainer title={"Hoeveel profijt heb jij van opgewekte zonnestroom?"} src={dummyGeld}>
                Bij veel huishoudens levert zonne-energie te weinig op.<br/><br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Een groot deel van eigen zonnestroom
                blijft onbenut.<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Niet gebruikte stroom keert terug naar
                het net.<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Energieleveranciers berekenen
                terugleverkosten.<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Zonder zonnestroom is er opnieuw
                netstroom nodig.<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Beloofde kostenbesparing van
                zonnepanelen blijft uit.<br/><br/>
                Het resultaat valt daardoor tegen en dat is frustrerend.<br/><br/>
                Duurzame energie hoort rendabel en effectief te zijn. Iedere zonnepaneeleigenaar verdient een systeem
                voor efficiënt behoudt en gebruik van eigen zonnestroom.
            </ArticleContainer>

            <Boxes title={"Gebruik tot 70% van de zonne-energie zelf"}  background={"rgb(247 247 247)"}
                   description={"En voorkom limitatie en uitschakeling van zonnepanelen bij overcapaciteit"}>
                <Box>
                    <h2 style={{textDecoration: "underline"}}>Verminder gasverbruik</h2>
                    <label>Zet zonnestroom in voor het verwarmen van water en ervaar direct resultaat bij een
                        douchebeurt of
                        badmoment.</label>
                </Box>
                <Box>
                    <h2 style={{textDecoration: "underline"}}>Benut eigen zonne-energie</h2>
                    <label>Maak effectief gebruik van iedere zonnestraal en wees duurzamer en minder afhankelijk van
                        fossiele brandstoffen.</label>
                </Box>
                <Box>
                    <h2 style={{textDecoration: "underline"}}>Verdien op jaarafrekening</h2>
                    <label>Ga slim om met energie in huis en profiteer minstens 7 maanden per jaar van een
                        energieneutrale woning.</label>
                </Box>
            </Boxes>

            <ArticleContainer2 title={"We begrijpen dat deze situatie niet eerlijk voelt, je bent niet de enige."}
                               src={dummyRemcoImage}>
                Dat is precies waarom RS-Solar Energy een multifunctioneel en betaalbaar systeem heeft ontwikkeld dat
                overtollige zonne- energie opslaat in een warmwaterboiler en toepast voor het verwarmen van tapwater in
                jouw woning.<br/><br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>25+ jaar elektra en techniek expert<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Grondlegger van RS-Solar Energy<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Eigen softwareschrijvers en updates<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Samenwerkingspartner van De Groene Hoed<br/>
                <b style={{color: "var(--main-background-color)"}}>&gt;</b>Vele tevreden klanten
            </ArticleContainer2>

            <Carousel/>

            <Boxes title={"Een compleet en plug & play systeem"}
                   description={"Voor elk type gebruiker en alle merken zonnepanelen"}>
                <img src={storyBoardImage} alt={""}
                     style={{width: "600px", maxWidth: "100%", marginLeft: "20px", marginRight: "20px"}}/>
            </Boxes>

            <Boxes title={"Start met benutten van overtollige zonne-energie"} background={"rgb(247 247 247)"}>
                <BoxWhite>
                    <h2 style={{
                        background: "var(--main-background-color)",
                        borderRadius: "5px",
                        padding: "1px 10px"
                    }}>Stap 1</h2>
                    <label style={{textDecoration: "underline", color: "black", fontWeight: "bold"}}>Vraag offerte
                        aan</label><br/>
                    <label style={{color: "black"}}>
                        Ontvang expertadvies en ontdek welk combinatiepakket het meest geschikt is voor jouw huishouden.
                    </label>
                </BoxWhite>
                <BoxWhite>
                    <h2 style={{
                        background: "var(--main-background-color)",
                        borderRadius: "5px",
                        padding: "1px 10px"
                    }}>Stap 2</h2>
                    <label style={{textDecoration: "underline", color: "black", fontWeight: "bold"}}>Gebruik het
                        systeem</label><br/>
                    <label style={{color: "black"}}>
                        Installeer RS-Solar Energy eenvoudig en sla overtollige zonne- energie op voor gebruik van warm
                        water in huis.
                    </label>
                </BoxWhite>
                <BoxWhite>
                    <h2 style={{
                        background: "var(--main-background-color)",
                        borderRadius: "5px",
                        padding: "1px 10px"
                    }}>Stap 3</h2>
                    <label style={{textDecoration: "underline", color: "black", fontWeight: "bold"}}>Maximaliseer
                        rendement</label><br/>
                    <label style={{color: "black"}}>
                        Profiteer optimaal van eigen zonnestroom, verdien aan duurzame energie en leef meer
                        zelfvoorzienend.
                    </label>
                </BoxWhite>
            </Boxes>
        </div>
    );
};

export function Carousel() {
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                backgroundImage: `url(${dummySolarHouse})`,
                backgroundSize: "cover",
                height: "430px"
            }}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                    <img src={chevronLeft} style={{height: "100px"}}/></div>
                <div style={{
                    maxWidth: "600px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <div style={{
                        backgroundColor: "rgba(255,255,255,0.9)",
                        borderRadius: "15px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        textAlign: "center",
                        boxShadow: "rgb(0 0 0 / 23%) 0px 0px 7px 0px"
                    }}>
                        Wat me het meest bevalt, is dat ik mijn zonne-energie niet meer verlies aan het net. RS- Solar
                        Energy heeft me geholpen om de controle te krijgen over mijn energieverbruik. Ik draag bij aan
                        een groenere toekomst en verdien ook nog eens aan mijn investering.<br/><br/>
                        -Karel Peters
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                    <img src={chevronRight} style={{height: "100px"}}/></div>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                <RedLinkButton name={"BESTEL RS-SOLAR"} to={"/winkel"}/>
                {/*<RedLinkButton name={"OVER ONS"}/>*/}
            </div>
        </div>
    )
}


type redLinkButtonProps = {
    name: string;
    to?: string;
    fontSize?: number;
}

export function RedLinkButton({name, to, fontSize = 16}: redLinkButtonProps) {
    return (
        <Link style={{
            background: "#bd0102",
            display: "inline-block",
            margin: "5px",
            padding: "10px",
            color: "white",
            borderRadius: "15px",
            textDecoration: "unset",
            fontSize: fontSize,
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 8px 0px"
        }} to={to ?? "/"}>
            <b>{name}</b>
        </Link>
    );
}

export function WhiteSpace() {
    return (
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", height: "60px"}}/>
    );
}